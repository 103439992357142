import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import faviconIco from '../assets/images/favicon.ico'
import cover from '../assets/images/home-hero.jpg'

/**
 * An SEO handler that gets data from each page
 * and assigns it to the Helmet.
 * @param {ComponentProps} props
 * @param {ChildNode} props.children
 * @param {String} props.title
 * @param {String} props.description
 * @param {String} props.pathname
 * @returns {StatelessComponent}
 */
const SEO = ({ children, title, description, pathname }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `)

  const seo = {
    title: `${title || data.site.siteMetadata.title} | Colorized.mk`,
    description: description || data.site.siteMetadata.description,
    image: cover,
    url: `${data.site.siteMetadata.siteUrl}${pathname || ``}`,
  }

  return (
    <>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="icon" href={faviconIco} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <title>{seo.title}</title>

        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta name="og:url" content={seo.url} />
        <meta propery="og:site_name" content={seo.title} />
        <meta name="og:image" content={seo.image} />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:url" content={seo.url} />
        <meta name="twitter:image" content={seo.image} />

        {children}
      </Helmet>
    </>
  )
}

SEO.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
}

export default SEO
